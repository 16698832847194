export const colors = {
    // primary: {
    //     '100': '#00ff08',
    //     '200': '#00e608',
    //     '300': '#00cc07',
    //     '400': '#00b306',
    //     '500': '#009905',
    //     '600': '#008004'
    // }

    primary: {
        '20': '#F1F9F3',
        '50': '#E2F3E7',
        '100': '#C5E7D0',
        '200': '#A9DBB9',
        '300': '#7EC995',
        '400': '#61BD7C',
        '500': '#429E5E',
        '600': '#36814D',
        '700': '#2A653C',
        '800': '#1E482B',
        '900': '#0C1D10',
    },
    secondary: {
        '20': '#EBFAFE',
        '50': '#C5F0FC',
        '100': '#B1EBFB',
        '200': '#9EE6FA',
        '300': '#8BE1F9',
        '400': '#64D7F7',
        '500': '#3DCDF5',
        '600': '#16C3F3',
        '700': '#0A9AC2',
        '800': '#076C88',
        '900': '#043E4E',
    }
}